//fonts
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@200;300;400;500;600;700;800&family=Manrope:wght@200;300;400;500;600;700;800&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Raleway:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&family=Tapestry&family=Water+Brush&family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');
//fonts

//components
@import "./../styles/Header/header";
@import "./../styles/Hero/hero";
@import "./../styles/Footer/footer";
@import "./../styles/Info/info";
@import "./../styles/Teachers/Teachers";
@import "./../styles/Gallery/gallery";
@import "./../styles/Services/services";
@import "./../styles/Chronology/chronology";
@import "./../styles/teachersPage/teachersPage";
@import "./../styles/Error/error";
@import "./../styles/tool/tool";
@import "./../styles/program/program";
@import "./../styles/CategoryDetails/categoryDetails";
@import "./../styles/admin/admin";
@import "./../styles/Details/Details.scss";

//components


//burgerMenu
@import "./navbar";
//burgerMenu


//media
@media (1440px) {
  .container {
    width: 90%;
  }
}
//media

// body {
//   background: rgba(93, 112, 210, 0.383);
// }