#CategoryDetail {
  padding: 100px 0;
  h1{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 27px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #252B42;
    margin-top: 20px;
    margin-bottom: 30px;
  }
}

.block {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.title {
  text-align: center;
  display: flex;
  gap: 20px;
}

.cards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  // padding: 20px 0;
  gap: 5px;
  // max-width: 700px;
  text-align: start;
 
.box{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;



  h3{
    padding: 10px;
  }
  .card {
    display: flex;
    align-items: end;
    gap: 20px;
    padding: 10px;
    border: solid 1px grey;
    border-radius: 5px;


    h2 {
    width: 400px;
    font-weight: 400;
    }
     .dow {
      font-size: 20px;
      display: flex;
     
     }
  }
}

@media(max-width:600px){
  .box{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
  
  
  
    h3{
      padding: 10px;
    }
    .card {
      display: flex;
      align-items: end;
      gap: 5px;
      padding: 10px;
      border: solid 1px grey;
      border-radius: 5px;
  
  
      h2 {
      width: 260px;
      font-weight: 400;
      }
       .dow {
        font-size: 20px;
        display: flex;
       
       }
    }
  }
}
}