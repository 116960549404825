#teach {
  padding-top: 100px;

  .teach-general {
    &__title {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 27px;
      text-align: center;
      letter-spacing: 0.2px;
      color: #000;
      margin-bottom: 50px;

    }

    &__block {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 20px;

      &__square {
        width: 306px;
        height: 550px;


        &__img {
          width: 100%;
          height: 350px;
          object-fit: cover;

          img {
            width: 100%;
            height: 350px;
            object-fit: cover;
            margin-bottom: 10px;
            border: 1px solid white;
          }
        }

        h1 {
          font-family: 'Montserrat', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          color: #000000;
          padding: 10px 0;
          text-align: center;
        }

        p {
          font-family: 'Montserrat', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          color: #000000;
          padding-bottom: 10px;
          text-align: center;
        }

        img {
          width: 301px;
        }
      }
    }
  }
}