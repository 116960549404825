html, body {
    scroll-behavior: smooth;
}

.container {
    width: 85%;
    margin: 0 auto;
    box-sizing: border-box;
}

a, a:hover {
    text-decoration: none;
}
* {
    margin: 0;
    padding: 0;
}

::-webkit-scrollbar {
    width: 15px;
}

::-webkit-scrollbar-track{
    background: #000000;
}

::-webkit-scrollbar-thumb{
    /*background: #2435A1;*/
    background: #273175;
    border-radius: 50px;
}