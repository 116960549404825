#tool {
  padding-top: 120px;

  .tool-general {
    &__title {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 27px;
      text-align: center;
      color: #000;
      margin-bottom: 70px;
    }

    &__block {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;

      button {
        margin: 10px 40px 40px 40px;
        width: 401px;
        height: 90px;
        padding: 5px 10px;
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        color: #fff;
        background: #00656B;
        border-radius: 16px;
        border: 1px solid #00656B;
        cursor: pointer;
        transition: .4s;

        &:hover {
          transform: scale(1.1);
          background: transparent;
          color: #00656B;
        }
      }
    }

    &__modal {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.66);
      backdrop-filter: blur(5px);
      position: fixed;
      top: 0;
      left: 0;
      z-index: 10;
      display: flex;
      justify-content: center;
      align-items: center;


      &__block {
        width: 70%;
        height: 500px;
        border-radius: 20px;
        border: 2px solid #00656B;
        margin-top: 70px;
        background: #fff;
        padding: 20px 80px;
        overflow-y: scroll;
        position: relative;
        animation: bl .3s linear alternate;
        @keyframes bl {
          0% {
            transform: scale(0);
            opacity: 0;
          }
          20% {
            transform: scale(.3);
          }
          40% {
            transform: scale(.5);
            opacity: .5;
          }
          60% {
            transform: scale(.7);
          }
          80% {
            transform: scale(1.2);
          }
          100% {
            transform: scale(1);
          }
        }

        h1 {
          color: red;
          position: fixed;
          right: 170px;
          cursor: pointer;
          font-size: 14px;
          transition: .4s;

          &:hover {
            transform: scale(1.1);
          }
        }

        &__nav {
          li {
            a {
              font-family: 'Montserrat', sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 22px;
              color: #000000;
              line-height: 40px;
            }
          }
        }
      }
    }
  }
}


@media (max-width: 768px) {
  #tool .tool-general__modal__block h1 {
    right: 52px;
  }
}

@media (max-width: 576px) {
  #tool .tool-general__modal__block {
    padding: 20px 69px 20px 34px;
  }
  #tool .tool-general__modal__block__nav li a {
    font-size: 16px;
  }
}

@media (max-width: 426px) {
  #tool .tool-general__block button {
    margin: 10px 40px 40px 40px;
    width: 300px;
    height: 59px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    color: #FFFFFF;
    background: #00656B;
    border-radius: 16px;
    border: 1px solid #00656B;
    cursor: pointer;
    transition: .4s;
  }
}

@media (max-width: 375px) {
  #tool .tool-general__modal__block {
    background: #fff;
  }
}