#chronology {
  padding: 80px 0;

  h1 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 27px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #252B42;
    margin-top: 20px;
  }

  .chronology__general {

    &--datas {
      margin: 50px 0;

      p {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: 0.2px;
        color: #000000;
      }

      &--data {
        display: flex;
        justify-content: space-between;
        margin: 20px 0;
        flex-wrap: wrap;

        &__titles {
          padding: 0 10px;

          h3 {
            font-family: 'Montserrat', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 0.2px;
            color: #252B42;
            margin-top: 20px;
          }

          p {
            font-family: 'Montserrat', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.2px;
            color: #000;
            margin-top: 10px;

          }

          h6 {
            font-family: 'Montserrat', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.2px;
            color: #000;
            margin-top: 10px;
          }

          h5 {
            font-family: 'Montserrat', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.2px;
            color: #252B42;
            margin-top: 10px;
            margin-bottom: 30px;
          }

          span {
            font-family: 'Montserrat', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 24px;
            letter-spacing: 0.2px;
            color: #000000;
          }
        }
      }
    }

  }
}