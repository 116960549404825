#admin {
  padding-top: 120px;

  .admin-general {
    &__title {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 27px;
      text-align: center;
      color: #252B42;
      margin-bottom: 30px;
    }

    &__block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      &__square {
        width: 320px;
        height: 400px;

        &__img {
          width: 300px;
          height: 250px;
          object-fit: contain;
         img {
           width: 300px;
           height: 250px;
           object-fit: contain;
           margin-bottom: 10px;
           border: 1px solid white;
         }
        }

        h1 {
          width: 90%;
          font-family: 'Montserrat', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          color: #000000;
          margin-bottom: 10px;
        }

        p {
          margin-bottom: 10px;
          font-family: 'Montserrat', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          color: #000000;
        }
      }
    }
  }
}

//administration